/**********************************************************************
*
*   Component hook generated by Quest
*
*   Code Logic for the component goes in this hook
*   To setup bindings that use the data here or call the functions here, use the Quest editor   
*
*   For help and further details refer to: https://www.quest.ai/docs
*
*
**********************************************************************/

import { useState } from "react";

import { ReactComponent as Home } from './assets/images/Home.svg';
import { ReactComponent as Account } from './assets/images/Account.svg';
import { ReactComponent as Projects } from './assets/images/Project.svg';
import { ReactComponent as Team } from './assets/images/Team.svg';
import { ReactComponent as Tickets } from './assets/images/Tickets.svg';
import { ReactComponent as Security } from './assets/images/Security.svg';
import { ReactComponent as Docs } from './assets/images/Docs.svg';
import { ReactComponent as Help } from './assets/images/Help.svg';





const useSidebar = () => {
  const sidebarItems = [
    {
      icon: Home,
      text: "Dashboard"
    },
    {
      icon: Account,
      text: "Account"
    },
    {
      icon: Projects,
      text: "Projects"
    },
    {
      icon: Team,
      text: "Team"
    },
    {
      icon: Tickets,
      text: "Tickets"
    },
    {
      icon: Security,
      text: "Security"
    },
    {
      icon: Docs,
      text: "Documentation"
    },
    {
      icon: Help,
      text: "Help"
    },
  ];

  let [selectedIndex, setSelectedIndex] = useState(0);
  let [isHover, setisHover] = useState(false);

  let data = { isHover, sidebarItems, selectedIndex };

  const handleClick = (index) => {
    setSelectedIndex(index);
  };

  const handleMouseOver = () => {
    setisHover(true);
  };

  const handleMouseOut = () => {
    setisHover(false);
  };

  let fns = { handleMouseOver, handleMouseOut, handleClick };
  return { data, fns };

}

export default useSidebar;