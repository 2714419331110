/**********************************************************************
*
*   Component hook generated by Quest
*
*   Code Logic for the component goes in this hook
*   To setup bindings that use the data here or call the functions here, use the Quest editor   
*
*   For help and further details refer to: https://www.quest.ai/docs
*
*
**********************************************************************/

import { useState } from "react";

const useFeedbackBar = () => {
  const reactions = [
    {
      emoji: "😎",
      text: "Today I feel great. This is the 49th time I've reported feeling this."
    },
    {
      emoji: "🙂",
      text: "Today I feel ok. This is the 23rd time I've reported feeling this."
    },
    {
      emoji: "😥",
      text: "Today I feel bad. This is the 15th time I've reported feeling this."
    },
    {
      emoji: "👎",
      text: "Today I feel horrible. This is the 3rd time I've reported feeling this."
    },
  ];

  let [selectedIndex, setSelectedIndex] = useState(-1);

  let data = { reactions, selectedIndex };

  const getFeedbackText = () => {
    let txt = "";
    if (selectedIndex === -1) {
      txt = "How are you feeling today?"
    }
    else {
      txt = reactions[selectedIndex].text;
    }

    return txt;
  };


  const handleClick = (index) => {
    setSelectedIndex(index);
  };

  let fns = { getFeedbackText, handleClick };
  return { data, fns };
}

export default useFeedbackBar;